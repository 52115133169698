import { FullComponent } from './layouts/full/full.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailFullComponent } from './pages-mailing/layouts/email-full/email-full.component';


const routes: Routes = [
  {
    path:'info',component:FullComponent,
    children:[
      {
        path:'interex',
        loadChildren:()=>import('./pages/pages.module').then(m=>m.PagesModule)
      },
      {
        path:'**',
        redirectTo:'info'
      }
    ]
  },
  {
    path:'email',component:EmailFullComponent,
    children:[
      {
        path:'interexMail',
        loadChildren:()=>import('./pages-mailing/pages-mailing.module').then(m=>m.MailingModule)
      },
      {
        path:'**',
        redirectTo:'email'
      }
    ]
  },
  {
    path:'quotation',
    children:[
      {
        path:'interexQuotation',
        loadChildren:()=>import('./pages-cotizacion/cotizacion.module').then(m=>m.QuotationgModule)
      },
      {
        path:'**',
        redirectTo:'quotation'
      }
    ]
  },
  {
    path:'**',
    redirectTo:'/info/interex/home'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

