<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-sidebartype="full" [ngClass]="{'show-sidebar': showMobileMenu, 'styling-format': true}" [dir]="options.dir" [attr.data-theme]="options.theme"
    [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype" [attr.data-sidebar-position]="options.sidebarpos"
    [attr.data-header-position]="options.headerpos" [attr.data-boxed-layout]="options.boxed">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
   <!--   ============================================================== -->
    <header class="topbar" [attr.data-navbarbg]="options.navbarbg">

    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="customizer" [ngClass]="{'show-service-panel': showSettings}">
        <div [ngClass]="{'jake1':showLogo}" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()">
            <a href="javascript:void(0)">
                <img src="../../../assets/images/home-resources/Layer-26.png" class="logoI" [ngClass]="{'show-logo': showLogo}" >
            </a>
            <div class="closeFormat"><a href="javascript:void(0)" style="text-decoration: none;color: white;">Close</a></div>
        </div>

        <div class="jake2">
            <img src="../../../assets/images/home-resources/Recurso 5.svg" class="svgPestana" [ngClass]="{'show-pes': showPes}">
        </div>
        <a href="javascript:void(0)" class="menu1" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()" *ngIf="showMenu">
            <p>- Menu -</p>
        </a>

        <div class="row customizer-body custom-pills">
        <div class="list-Automargin border-right">

            <div class="lista">
                <!-- Sidebar (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()" -->
                <ul class="list-spaces" *ngIf="_isLoggedIn===true">
                    <li class="menuInternoTop menuInterno " ><a routerLink="interexMail/home" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Home</a></li>
                    <li class="menuInterno" ><a routerLink="interexMail/Email" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Email</a></li>
                    <li class="menuInterno"><a routerLink="interexMail/Camping" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Campaign</a></li>
                    <li class="menuInterno"><a routerLink="interexMail/Template" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Template</a></li>
                    <li class="menuInterno"><a routerLink="/info/interx/home" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle(); cleanLoggedIn(); "><span class="bulletFormat">•</span>Logout</a></li>
                    <img src="../../../assets/images/home-resources/Layer-26.png" routerLink="/quotation/interexQuotation/list-client" class="secret" *ngIf="secret==true" >
                </ul>
                <ul class="list-spaces" *ngIf="_isLoggedIn===false">
                    <li class="menuInternoTop menuInterno " ><a routerLink="interexMail/Login" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Login</a></li>
                    <!-- <li class="menuInterno" ><a routerLink="interexMail/Register" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; MenuToogle()"><span class="bulletFormat">•</span>Register</a></li> -->
                </ul>
            </div>
        </div>
        <div class="content1">
            <div class="info1">
                <h3 class="tituloInfo">Mexico City</h3>
                <p class="contenido">Our offices are the gateway to Latin America, located at south of Mexico City by Llanuras del Ajusco. Seeking the view of the Iztlacci´huatl and Popocate´petl volcanoes.</p>
            </div>
            <div class="info2">
                <h3 class="tituloInfo">US Austin | Texas</h3>
                <p class="contenido">Better decisions start with better weather, that's why we are neighbors of great companies such as Apple and Oracle !</p>
            </div>
            <div class="row info3">
                <a href="https://www.facebook.com/expansioninteractiva" class="col tituloInfo">FB</a>
                <P class="espacio">-</P>
                <a href="http://www.instagram.com/interex.us" class="col tituloInfo">IG</a>
                <p class="espacio">-</p>
                <a href="https://twitter.com/InteractiveExp1" class="col tituloInfo">TW</a>
                <p class="espacio">-</p>
                <a href="https://www.linkedin.com/company/22313605/admin/" class="col tituloInfo">LN</a>
            </div>
        </div>
        </div>
    </aside>

    <nav role="navigation" class="navigation">
        <div id="menuToggle">
          <!--
          A fake / hidden checkbox is used as click reciever,
          so you can use the :checked selector on it.
          -->
          <input class="responsiveCheckbox" type="checkbox" />
          
          <!--
          Some spans to act as a hamburger.
          
          They are acting like a real hamburger,
          not that McDonalds stuff.
          -->
          <span></span>
          <span></span>
          <span></span>
          
          <!--
          Too bad the menu has to be inside of the button
          but hey, it's pure CSS magic.
          -->
          <ul class="list-spaces" id="menu" *ngIf="_isLoggedIn===true">
            <li class="menuInterno"> <a routerLink="interexMail/home"  (click)="closeMenu()">Home</a></li>
            <li class="menuInterno"> <a routerLink="interexMail/Email" (click)="closeMenu()">Email</a></li>
            <li class="menuInterno"> <a routerLink="interexMail/Camping" (click)="closeMenu()">Campaign</a></li>
            <li class="menuInterno"> <a routerLink="interexMail/Template" (click)="closeMenu()">Template</a></li>
           <li class="menuInterno"> <a routerLink="/info/interx/home"  (click)="cleanLoggedIn();closeMenu();">Logout</a></li>
          </ul>
          <ul class="list-spaces" id="menu" *ngIf="_isLoggedIn===false">
            <li> <a routerLink="interexMail/Login" (click)="closeMenu()">  Login </a></li>
            <li> <a routerLink="interexMail/Register" (click)="closeMenu()"> Register </a></li>
          </ul>
        </div>
      </nav>
      
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->

    <!-- Page wrapper
     ==============================================================-->
    <div class="page-wrapper" id="slide-BG">
        <!-- <div class="container-fluid"> -->
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        <!-- </div> -->
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>
