import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, catchError, finalize, switchMap, tap, throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TokenStorageService } from '../services/token-storage.service';
import { ModalService } from '../services/modal.service';

@Injectable({ providedIn: 'root' })
export class TokeninterceptorService implements HttpInterceptor {
  platformId: Object
  private isRefreshing = false;

  constructor(@Inject(PLATFORM_ID) platformId: Object, private tokenService: TokenStorageService, private modalService: ModalService) {
    this.platformId = platformId;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (isPlatformBrowser) {
      const idToken = this.tokenService.getAuthToken();

      if (idToken) {
        console.log("Paso por interceptor", idToken);
        req = req.clone({
          headers: req.headers.set("Authorization",
            "Bearer " + idToken),
          withCredentials: true
        });
      }
    }
    
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleUnauthorizedError(req, next);
        }
        return throwError(() => error);
      })
    );
  }

  handleUnauthorizedError(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.tokenService.refreshAccessToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          this.tokenService.setAuthToken(res.accessToken);
          return next.handle(req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + res.accessToken) }));
        }),
        catchError(() => {
          return this.modalService.openModal(null, {data: { duration: 10 }, panelClass: "modal-dialog", autoFocus: false, disableClose: true}).afterClosed().pipe(
            switchMap(res => {
              console.log('result dialog',res)
              if (res) {
                return this.tokenService.renewRefreshToken().pipe(
                  switchMap(() => {
                    
                    return next.handle(req);
                  }),
                  catchError(() => {
                    this.tokenService.cleanLoggedIn();
                    return EMPTY;
                  })
                );
              }
              this.tokenService.cleanLoggedIn();
              return EMPTY;
            })
          );
        }),
        finalize(() => { this.isRefreshing = false; return EMPTY; })
      );
    }

    return next.handle(req);
  }
}
