import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { retry, catchError } from 'rxjs/operators';
import { Subject, Observable, throwError} from 'rxjs';
import {template}  from '../models/template';
import {campaign}  from '../models/CampaignSchedulers';
import {category}  from '../models/category';
import {environment} from '../../environments/environment'

@Injectable()
export class GeneralService {

  constructor(
    private http: HttpClient,
    private titleService: Title
  ) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  // Header alert text
  HeaderMessage = new Subject<string>();
  HeaderMessage$ = this.HeaderMessage.asObservable();

  changeHeaderMessage(data) {
    this.HeaderMessage.next(data);
  }
  base_url = environment.base_urlNode;
  main_url = '/';

  getJobsFullList() {
    return this.http.get<any[]>('https://geekandgo.work/api/jobListings')
  }

  emailSendMarketing(value){
    return this.http.post(this.base_url +  '/sendgrid/sendemailRegister',value) ;
  }
  templateFullList(){
    return this.http.get<template[]>(this.base_url +  '/template/templateFullList') ;
  }
  templateFullListSub(){
    return this.http.get<template[]>(this.base_url +  '/template/templateFullListSub') ;
  }
  campaignSchedulerFullList(){
    return this.http.get<campaign[]> (this.base_url +  '/campaignScheduler/campaignSchedulerFullList') ;
  }
  campaignSchedulerFullListSub(){
    return this.http.get<campaign[]> (this.base_url +  '/campaignScheduler/campaignSchedulerFullListSub') ;
  }
  campaignSchedulerGetOne(value){
    return this.http.get(this.base_url +  '/campaignScheduler/campaignSchedulerGetOne/'+value) ;
  }
clikHtml(){
  return this.http.get<template[]>(this.base_url +  '/template/templateFullList') ;
}

  templateGebyCategory(value){
    return this.http.get<template[]>(this.base_url +  '/template/templateGebyCategory/'+value) ;
  }

  categoryFullList(){
    return this.http.get<category[]>(this.base_url +  '/categories/categoryFullList') ;
  }
  dateFullList(){
    return this.http.get<category[]>(this.base_url +  '/date/dateFullList') ;
  }

  checkUserLogin(data){
    let postData = { 'email': data.email, 'password': data.loginPassword }
    return this.http.post(this.base_url + '/auth/login', postData, {...this.httpOptions, withCredentials: true})
  }
  registerUser(data){
    // let postData = { 'email': data.email, 'password': data.loginPassword }
    return this.http.post(this.base_url + '/auth/register', data, this.httpOptions)
  }

  registersingle(data){
    let postData = { 'email': data }
    return this.http.post(this.base_url + '/auth/registersingle', postData, this.httpOptions)
  }

  mailupdate(data, email){
    let postData = { 'email': email, 'password': data.password }
    return this.http.post(this.base_url + '/auth/mailupdate', postData, this.httpOptions)
  }

  suscribeRegister(value){
    return this.http.get(this.base_url +  '/registersuscribe/findsuscribe/'+value) ;
  }

  contactSend(value){
    return this.http.post(this.base_url +  '/contact/contactRegister',value) ;
  }

  getIpCliente(){
    return this.http.get('http://www.geoplugin.net/json.gp').pipe(
      retry(2),
      catchError(this.handleError)); // ...and calling .json() on the response to return data
    //.catch((error:any) => Observable.throw(error.json().error || 'Server error')); //...errors if any
    }
    handleError(error: HttpErrorResponse){
    console.log("lalalalalalalala");
    return throwError(error);
    }


    webinarSubcribeRegister(value){
      return this.http.post(this.base_url +  '/webinarSubcribe/webinarSubcribeRegister',value) ;
    }
    webinarSubcribeFullList(){
      return this.http.get(this.base_url +  '/webinarSubcribe/webinarSubcribeFullList') ;
    }
    webinarSubcribeGetOne(value){
      return this.http.get(this.base_url +  '/webinarSubcribe/webinarSubcribeGetOne/'+value) ;
    }

    industryRegister(value){
      return this.http.post(this.base_url +  '/industry/industryRegister',value) ;
    }
    industryFullList(){
      return this.http.get(this.base_url +  '/industry/industryFullList') ;
    }

    prospectRegister(value){
      return this.http.post(this.base_url +  '/prospect/prospectRegister',value) ;
    }
    prospectFullList(){
      return this.http.get(this.base_url +  '/prospect/prospectFullList') ;
    }

    clientRegister(value){
      return this.http.post(this.base_url +  '/client/clientRegister',value) ;
    }
    clientFullList(){
      return this.http.get(this.base_url +  '/client/clientFullList') ;
    }


    prospectAddressRegister(value){
      return this.http.post(this.base_url +  '/prospectAddress/prospectAddressRegister',value) ;
    }
    prospectAddessFullList(){
      return this.http.get(this.base_url +  '/prospectAddress/prospectAddressFullList') ;
    }

    clientAddressRegister(value){
      return this.http.post(this.base_url +  '/clientAddress/clientAddressRegister',value) ;
    }
    clientAddessFullList(){
      return this.http.get(this.base_url +  '/clientAddress/clientAddressFullList') ;
    }

    prospectCompanyRegister(value){
      return this.http.post(this.base_url +  '/prospectCompany/prospectCompanyRegister',value) ;
    }
    prospectCompanyFullList(){
      return this.http.get(this.base_url +  '/prospectCompany/prospectCompanyFullList') ;
    }

    clientCompanyRegister(value){
      return this.http.post(this.base_url +  '/clientCompany/clientCompanyRegister',value) ;
    }
    clientCompanyFullList(){
      return this.http.get(this.base_url +  '/clientCompany/clientCompanyFullList') ;
    }

    prospectSiteRegister(value){
      return this.http.post(this.base_url +  '/prospectSite/prospectSiteRegister',value) ;
    }
    prospectSiteFullList(){
      return this.http.get(this.base_url +  '/prospectSite/prospectSiteFullList') ;
    }

    clientSiteRegister(value){
      return this.http.post(this.base_url +  '/clientSite/clientSiteRegister',value) ;
    }
    clientSiteFullList(){
      return this.http.get(this.base_url +  '/clientSite/clientSiteFullList') ;
    }

    prospectComentRegister(value){
      return this.http.post(this.base_url +  '/prospectComent/prospectComentRegister',value) ;
    }
    prospectComentFullList(){
      return this.http.get(this.base_url +  '/prospectComent/prospectComentFullList') ;
    }

    clientComentRegister(value){
      return this.http.post(this.base_url +  '/clientComent/clientComentRegister',value) ;
    }
    clientComentFullList(){
      return this.http.get(this.base_url +  '/clientComent/clientComentFullList') ;
    }

    TemplateServicesRegister(value){
      return this.http.post(this.base_url +  '/TemplateServices/TemplateServicesRegister',value) ;
    }
    TemplateServicesFullList(){
      return this.http.get(this.base_url +  '/TemplateServices/TemplateServicesFullList') ;
    }


    webinarRegister(value){
      return this.http.post(this.base_url +  '/webinar/webinarRegister',value) ;
    }
    webinarFullList(){
      return this.http.get(this.base_url +  '/webinar/webinarFullList') ;
    }
    webinarGetOne(value){
      return this.http.get(this.base_url +  '/webinar/webinarGetOne/'+value) ;
    }
    usersWebinarRegister(value){
      return this.http.post(this.base_url +  '/webinar/users/usersWebinarRegister',value) ;
    }
    cheackEmail(email,code,webinar){
      let postData = { 'email': email, 'code': code,'webinar':webinar }
      return this.http.post(this.base_url + '/webinar/users/userComprobacion', postData);

    }



}
