import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class JavaScriptService {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  carga(archivos:string[]){
    for(let archivo of archivos){
      let script = document.createElement("script");
      script.src="./assets/Js/" + archivo + ".js";
      let body = document.getElementsByTagName("body")[0];
      body.appendChild(script);
    }
  }

}
