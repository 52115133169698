import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { InterxLoginComponent } from '../pages-mailing/interx-login/interx-login.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const TOKEN_KEY = 'auth-token';
const ROL_KEY = 'auth-rol';
const USER_KEY = 'auth-user';
@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    private accessToken: string | null = null;

    base_url = environment.base_urlNode;
    var = 'holamundo';
    login = false;
    roles = '';
    _otp = false;

    tok = '';
    getRol = '';

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        private cookie: CookieService,
        private http: HttpClient
        // public log: InterxLoginComponent
    ) { }

    // role=this.log.role;

    signOut() {
        window.sessionStorage.clear();
    }

    public saveToken(token: string) {
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, token);
        // localStorage.clear();
        // localStorage.setItem(TOKEN_KEY, token);
        this.cookie.set(TOKEN_KEY, token)
    }

    public getToken(): string {
        return sessionStorage.getItem(TOKEN_KEY);
    }

    public saveRol(rol: string) {

        window.sessionStorage.removeItem(ROL_KEY);
        window.sessionStorage.setItem(ROL_KEY, rol);
        // localStorage.clear();
        this.cookie.set(ROL_KEY, rol);
        this.roles = rol;
    }

    isLoggedIn() {
        var rol = this.cookie.get('auth-rol');
        if (this.accessToken) {
            this.login = true;
            this.tok = this.accessToken;
            this.getRol = rol;
            return true;
        }
        else
            return false;
    }

    cleanLoggedIn() {
        //localStorage.clear();
        this.router.navigate(['/email/interexMail/home']);
        this._otp = false;
        this.http.post(`${this.base_url}/auth/logout`, {}, {withCredentials: true}).subscribe(res=> {this.accessToken = null; console.log(res, this.accessToken)})
    }
    makeOTP() {
        this._otp = true;
    }

    getAuthToken() {
        return this.accessToken;
    }

    setAuthToken(token) {
        this.accessToken = token;
    }

    refreshAccessToken() {
        return this.http.post(`${this.base_url}/auth/refresh`, {}, {withCredentials: true});
    }

    renewRefreshToken() {
        return this.http.post(`${this.base_url}/auth/renewrefreshtoken`, {}, { withCredentials: true });
    }
}
