import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from './services/interceptor.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JavaScriptService } from './services/javascript.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { SpinnerComponent } from './shared/spinner.component';
import {NgbPaginationModule, NgbAlertModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { VgCoreModule } from 'ngx-videogular';

import { MatCardModule } from '@angular/material/card';
import { FadeInAnimationDirective } from '../app/directives/gsap/fade-in-animation.directive';
import { CookieService } from 'ngx-cookie-service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20
};
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullComponent } from './layouts/full/full.component';
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin.js";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
//INICIO SERVICIO JS
import { CargarscriptsService } from './cargarscripts.service';
//FIN SERVICIO JS


import { GeneralService } from './services/generalService';
import { TokeninterceptorService } from './interceptors/tokeninterceptor.service';
import { FilesService } from './services/files.service';
import { TermsAndConditionsComponent } from './pages-mailing/terms-and-conditions/terms-and-conditions.component';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        NavigationComponent,
        SidebarComponent,
        SpinnerComponent,
        FadeInAnimationDirective
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        // NgParticlesModule,
        NgbPaginationModule,
        NgbAlertModule,
        NgbModule,
        PerfectScrollbarModule,
        //ParticlesModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatCardModule,
        VgCoreModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        NgxSpinnerModule,
        MatDialogModule
    ],
    providers: [
        CargarscriptsService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        JavaScriptService,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        GeneralService, {
            provide: HTTP_INTERCEPTORS,
            useClass: TokeninterceptorService,
            multi: true
        },
        FilesService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CookieService
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
