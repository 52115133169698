import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  static isBrowser = new BehaviorSubject<boolean>(null);

  title = 'InterX Digital Solutions';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showLogo = false;
  public showPes = false;
  public showMenu= true;
  public showMobileMenu = false;
  public expandLogo = false;
  public showAplicationLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5' // six possible values: skin(1/2/3/4/5/6)
  };

  constructor(public router: Router, @Inject(PLATFORM_ID) private platformId: any){
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(){

  }

toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  testJake(){
    console.log("testJake");
    if(this.showMenu == false){ this.showMenu = true} else if (this.showMenu = true){
      this.showMenu = false;
    }
  }
}
