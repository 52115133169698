import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../shared/modal/modal.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private matDialog: MatDialog
  ) { }

  openModal(data?: TemplateRef<any>, options?: MatDialogConfig) {
    return this.matDialog.open(ModalComponent, {data, width: '100%',maxWidth: 500, ...options})
  }

  closeModal() {
    this.matDialog.closeAll()
  }
}
