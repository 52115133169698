<div class="pe-auto">
    <div class="">
        <h2 mat-dialog-title>Session timeout</h2>
    </div>
    <mat-dialog-content>
        <p>Please choose to stay signed in or to logoff. Otherwise, you will logged off automatically</p>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Log Off</button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true">Stay logged in ({{countdown}})</button>
    </mat-dialog-actions>
</div>