import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  countdown : number;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.countdown = data.duration;
  }

  ngOnInit(): void {
    const interval = setInterval(() => {
      this.countdown--;

      if(this.countdown <= 0) {
        clearInterval(interval);
        this.dialogRef.close();
      }
    }, 1000);
  }

}
