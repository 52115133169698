import { Component, OnInit, HostListener, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { timer } from 'rxjs';
import { LoaderService } from './../../../services/loader.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../../services/generalService';
import { TokenStorageService } from '../../../services/token-storage.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-email-full',
  templateUrl: './email-full.component.html',
  styleUrls: ['./email-full.component.scss'],
})
export class EmailFullComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  public _isLoggedIn = false;
  secret=false;

  constructor(
    public router: Router, 
    public loaderSvc:LoaderService, 
    public generalService: GeneralService,
    public  loginService: TokenStorageService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document
    ) { }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showLogo = false;
  public showPes = false;
  public showMenu= true;
  public showMobileMenu = false;
  public expandLogo = false;
  public showAplicationLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    /** spinner starts on init */
            this.spinner.show();

            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 4000);

    this.loginIn();
  }

  loginIn(){
    const source = timer(1000, 1000);
    const abc = source.subscribe(val => {
    this._isLoggedIn = this.loginService.isLoggedIn();
    this.secret=this.loginService._otp;
    // console.log('OTP',this.secret);
    });
  }
  cleanLoggedIn(){
    this.loginService.cleanLoggedIn();
    this._isLoggedIn = false;
    this.secret=false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
MenuToogle(){
    console.log("MenuToogle");
    if(this.showMenu == false){ this.showMenu = true} else if (this.showMenu = true){
      this.showMenu = false;
    }
  }

  closeMenu(){
    let closeM = document.querySelector('.responsiveCheckbox')[0].checked;
    console.log('closeM:', closeM);
  }

}
