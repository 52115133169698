<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" data-sidebartype="full" [ngClass]="{'show-sidebar': showMobileMenu, 'styling-format': true}" [dir]="options.dir" [attr.data-theme]="options.theme"
    [attr.data-layout]="options.layout" [attr.data-sidebartype]="options.sidebartype" [attr.data-sidebar-position]="options.sidebarpos"
    [attr.data-header-position]="options.headerpos" [attr.data-boxed-layout]="options.boxed">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
   <!--   ============================================================== -->
    <header class="topbar" [attr.data-navbarbg]="options.navbarbg">

    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!--    <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()"> -->
            <!-- Sidebar scroll-->
    <!--        <div class="scroll-sidebar" [perfectScrollbar]="config">
                <app-sidebar></app-sidebar>
            </div> -->
            <!-- End Sidebar scroll-->
    <!--    </aside> -->
    <aside class="customizer" [ngClass]="{'show-service-panel': showSettings}">
        <div [ngClass]="{'jake1':showLogo}" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()">
            <a href="javascript:void(0)">
                <img src="../../../assets/images/home-resources/Layer-26.png" class="logoI" [ngClass]="{'show-logo': showLogo}" >
            </a>
            <div class="closeFormat"><a href="javascript:void(0)" style="text-decoration: none;color: white;">Close</a></div>
        </div>

        <div class="jake2">
            <img src="../../../assets/images/home-resources/Recurso 5.svg" class="svgPestana" [ngClass]="{'show-pes': showPes}">
        </div>
        <a href="javascript:void(0)" class="menu1" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()" *ngIf="showMenu">
            <p>- Menu -</p>
        </a>

        <div class="row customizer-body custom-pills">
        <div class="list-Automargin border-right">

            <div class="lista">
                <!-- Sidebar (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()" -->
                <ul class="list-spaces" >
                    <li class="menuInternoTop menuInterno " ><a routerLink="interex/home" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()"><span class="bulletFormat">•</span>Home</a></li>
                    <li class="menuInterno" ><a routerLink="interex/about" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()"><span class="bulletFormat">•</span>About</a></li>
                    <li class="menuInterno" ><a (click)="goToUrl()"><span class="bulletFormat">•</span>Services</a></li>
                    <li class="menuInterno"><a routerLink="interex/portfolio" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()"><span class="bulletFormat">•</span>Experience</a></li>
                    <li class="menuInterno"><a routerLink="/email/interexMail/home" (click)="showLogo = !showLogo; showPes = !showPes; showSettings = !showSettings;showAplicationLogo = !showAplicationLogo; testJake()"><span class="bulletFormat">•</span>Email Marketing</a></li>
                </ul>
            </div>
        </div>
        <div class="content1">
            <div class="info1">
                <h3 class="tituloInfo">Mexico City</h3>
                <p class="contenido">Our offices are the gateway to Latin America, located at south of Mexico City by Llanuras del Ajusco. Seeking the view of the Iztlacci´huatl and Popocate´petl volcanoes.</p>
            </div>
            <div class="info2">
                <h3 class="tituloInfo">US Austin | Texas</h3>
                <p class="contenido">Better decisions start with better weather, that's why we are neighbors of great companies such as Apple and Oracle !</p>
            </div>
            <div class="row info3">
                <a href="https://www.facebook.com/expansioninteractiva" class="col tituloInfo">FB</a>
                <P class="espacio">-</P>
                <a href="http://www.instagram.com/interex.us" class="col tituloInfo">IG</a>
                <p class="espacio">-</p>
                <a href="https://twitter.com/InteractiveExp1" class="col tituloInfo">TW</a>
                <p class="espacio">-</p>
                <a href="https://www.linkedin.com/company/22313605/admin/" class="col tituloInfo">LN</a>
            </div>
        </div>
        </div>
    </aside>

    <nav role="navigation" class="navigation">
        <div id="menuToggle">
          <!--
          A fake / hidden checkbox is used as click reciever,
          so you can use the :checked selector on it.
          -->
          <input class="responsiveCheckbox" type="checkbox" />
          
          <!--
          Some spans to act as a hamburger.
          
          They are acting like a real hamburger,
          not that McDonalds stuff.
          -->
          <span></span>
          <span></span>
          <span></span>
          
          <!--
          Too bad the menu has to be inside of the button
          but hey, it's pure CSS magic.
          -->
          <ul id="menu">
            <li class="menuInterno"> <a routerLink="interex/home" (click)="closeMenu()">Home</a></li>
            <li class="menuInterno"> <a routerLink="interex/about" (click)="closeMenu()">About</a></li>
            <li class="menuInterno"> <a routerLink="interex/portfolio" (click)="closeMenu()">Experience</a></li>
            <!-- <a routerLink="info/interex/blog" (click)="closeMenu()" *ngIf="_isLoggedIn===true"><li>Works</li></a> -->
            <li class="menuInterno"> <a href="mailto:jacob@interex.us" (click)="closeMenu()">Contact</a></li>
          </ul>
        </div>
      </nav>
      
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->

    <!-- Page wrapper
     ==============================================================-->
    <div class="page-wrapper" id="slide-BG">
      <!--  <img src="../../../assets/images/home-resources/Interex_final_vector.png" alt="interex" class="interexHeadLogo" [ngClass]="{'show-aplicationlogo': showAplicationLogo}">-->
        <!--<app-breadcrumb></app-breadcrumb>-->
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ==============================================================
        <ngx-spinner bdColor="rgb(0,0,0,1)" size="medium" color="#fff" type="none" [fullScreen]="true" >
          <img id="spinner" src="../../../assets/images/home-resources/Interex_final_vector.png" style="width: 30%; position: relative;">
          <p style="color: white">  </p>
        </ngx-spinner>-->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->


</div>
